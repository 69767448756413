import {
  compose,
  reduce,
  mergeRight,
  map,
  zip,
  assoc,
  replace,
  toLower,
} from 'ramda'

const isSlovak = process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk'

export const COLORS = {
  white: 'hsl(0deg 0% 100%)',
  whiteOpacity28: 'hsl(0deg 0% 100% / 28%)',
  whiteOpacity39: 'hsl(0deg 0% 100% / 39%)',
  whiteOpacity70: 'hsl(0deg 0% 100% / 70%)',
  whiteOpacity79: 'hsl(0deg 0% 100% / 79%)',
  black: 'hsl(0deg, 0% 0%)',
  blackOpacity13: 'hsl(0deg 0% 0% / 13%)',
  grey80: 'hsl(0deg 0% 96%)', //#F5F5F5
  grey86: 'hsl(0deg 0% 93%)', //#EDEDED
  grey85: 'hsl(0deg 0% 92%)', //#EAEAEA
  grey85opacity50: 'hsla(0deg 0% 92% / 50%)',
  grey90: 'hsl(0deg 3% 85%)', //#D9D7D7
  grey90opacity39: 'hsla(0deg 3% 85% / 39%)',
  grey95: 'hsl(0deg 0% 81%)', //#CECECE
  grey100: 'hsl(0deg 0% 69%)', //#AFAFAF
  grey100opacity39: 'hsl(0deg 0% 69% / 39%)', //#AFAFAF
  grey105: 'hsl(0deg 0% 60%)', //#989898
  grey110: 'hsl(0deg 0% 47%)', //#777777
  grey120: 'hsl(0deg 0% 34%)', //#575757
  red100: 'hsl(354deg 100% 43%)', //#D90017
  red110: 'hsl(354deg 88% 32%)',
  red120: 'hsl(353deg 100% 21%)',
  transparent: 'transparent',
}

export const GRADIENTS = {
  red100ToRed120: 'linear-gradient(to right, #d90017 0%, #6d000c 100%)',
  grey86ToGrey80: 'linear-gradient(to top, #ededed 0%, #f5f5f5 100%)',
}

export const BREAKPOINTS = {
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
  xxxl: 1920,
}

export const LOCALES = isSlovak ? ['sk', 'en'] : ['cs', 'en']

export const REGIONS_CS = [
  'Hlavní město Praha',
  'Středočeský kraj',
  'Jihočeský kraj',
  'Plzeňský kraj',
  'Karlovarský kraj',
  'Ústecký kraj',
  'Liberecký kraj',
  'Pardubický kraj',
  'Vysočina',
  'Jihomoravský kraj',
  'Olomoucký kraj',
  'Královehradecký kraj',
  'Zlínský kraj',
  'Moravskoslezský kraj',
]

export const REGIONS_SK = [
  'Bratislavský kraj',
  'Trnavský kraj',
  'Trenčínský kraj',
  'Nitrianský kraj',
  'Žilinský kraj',
  'Banskobystrický kraj',
  'Prešovský kraj',
  'Košický kraj',
]

export const REGIONS = isSlovak ? REGIONS_SK : REGIONS_CS

const ALL_REGIONS = [...REGIONS_CS, ...REGIONS_SK]

// Region bilateral translation map for url/name
// ustecky-kraj -> Ústecký kraj
// Ústecký kraj -> ustecky-kraj
export const REGIONS_TRANSLATION_MAP = compose(
  reduce(mergeRight, {} as any),
  map((names: [string, string]) =>
    assoc(names[1], names[0], assoc(names[0], names[1], {})),
  ),
  zip(ALL_REGIONS),
  map(replace(/č/g, 'c')),
  map(replace(/š/g, 's')),
  map(replace(/ů/g, 'u')),
  map(replace(/ú/g, 'u')),
  map(replace(/ň/g, 'n')),
  map(replace(/ý/g, 'y')),
  map(replace(/í/g, 'i')),
  map(replace(/ř/g, 'r')),
  map(replace(/ž/g, 'z')),
  map(replace(/ě/g, 'e')),
  map(replace(/á/g, 'a')),
  map(toLower),
  map(replace(/ /g, '-')),
)(ALL_REGIONS) as { [prop: string]: string }

export const apiRegionKeyToSlug = compose(replace(/_/g, '-'), toLower)

export const TABS_MEDIA = [
  { id: 1, title: 'kontakt', href: '/' },
  { id: 2, title: 'tiskové zprávy', href: '/' },
  { id: 3, title: 'ke stažení', href: '/' },
]

export const TABS_CONTACT = [
  { id: 1, title: 'zobrazit vše', href: '/' },
  { id: 2, title: 'provozovny', href: '/' },
  { id: 3, title: 'sběrné dvory a skládky', href: '/' },
]

export const TABS_ABOUT = [
  { id: 1, title: 'naše mise', href: '#vision' },
  { id: 2, title: 'korporátní video', href: '#korporatni-video' },
  { id: 3, title: 'sponzoring', href: '#sponzoring' },
  { id: 4, title: 'certifikace', href: '#certifikace' },
  { id: 5, title: 'ke stažení', href: '#ke-stazeni' },
  { id: 5, title: 'společenská odpovědnost', href: '#responsibility' },
]

export const TABS_CAREER = [
  { id: 1, title: 'proč ave', href: '#proc-ave' },
  { id: 2, title: 'o nás', href: '#o-nas' },
  { id: 3, title: 'co nabízíme', href: '#co-nabizime' },
  { id: 4, title: 'firemní kultura', href: '#firemni-kultura' },
  { id: 5, title: 'společenská odpovědnost', href: '#spolecenska-odpovednost' },
]

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
